<template>
  <div class="flex items-center gap-x-2">
    <button
      type="button"
      class="flex items-center justify-center"
      @click="onCheck"
    >
      <div
        class="w-5 h-5 rounded flex items-center justify-center cursor-pointer"
        :class="{
          'bg-WAORANGE-500': checked,
          'border-2 border-gray-200': !checked,
        }"
      >
        <img v-if="checked" src="/check-icon.svg" alt="check" class="w-3" />
      </div>
    </button>
    <button
      v-if="label"
      class="text-gray-600 text-sm"
      type="button"
      @click="onCheck"
    >
      {{ label }}
    </button>
  </div>
</template>

<script setup>
import { defineProps, defineEmits, computed } from "vue";

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },
  label: String,
  value: [String, Number],
});

const emit = defineEmits(["update:modelValue"]);
const onCheck = () => {
  if (Array.isArray(props.modelValue)) {
    let values = props.modelValue;

    if (values.includes(props.value))
      values = values.filter((v) => v !== props.value);
    else values.push(props.value);

    emit("update:modelValue", values);
  } else emit("update:modelValue", !props.modelValue);
};

const checked = computed(() => {
  if (Array.isArray(props.modelValue))
    return props.modelValue.includes(props.value);

  return !!props.modelValue;
});
</script>
