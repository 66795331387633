<template>
  <div class="max-w-screen-2xl mx-auto px-4 py-2 lg:px-4">
    <div class="flex items-center justify-between">
      <div class="flex flex-row">
        <sort-label
          name="name"
          :placeholder="$t('name')"
          classes="flex pr-1 w-44 md:w-80"
          :sortData="sort"
          @sort="onSort"
        ></sort-label>

        <sort-label
          name="formNumber"
          :placeholder="$t('form-number')"
          classes="hidden px-1 w-32 md:flex"
          :sortData="sort"
          @sort="onSort"
        ></sort-label>

        <!-- <sort-label
          name="type"
          :placeholder="$t('form-type')"
          classes="hidden px-1 w-36 lg:flex"
          :sortData="sort"
          @sort="onSort"
        ></sort-label> -->

        <sort-label
          name="group"
          :placeholder="$t('group')"
          classes="hidden px-1 w-36 lg:flex"
          :sortData="sort"
          @sort="onSort"
        ></sort-label>

        <sort-label
          name="subgroup"
          :placeholder="$t('sub-group')"
          classes="hidden px-1 w-44 lg:flex"
          :sortData="sort"
          @sort="onSort"
        ></sort-label>

        <sort-label
          name="sub_subgroup"
          :placeholder="$t('sub-sub-group')"
          classes="hidden px-1 w-36 lg:flex"
          :sortData="sort"
          @sort="onSort"
        ></sort-label>
      </div>
      <div class="flex flex-row">
        <sort-label
          name="status"
          :placeholder="$t('status')"
          classes="flex justify-end w-20 sm:flex"
          :sortData="sort"
          @sort="onSort"
        ></sort-label>
        <div class="flex justify-end w-20 sm:flex">
          <p class="font-semibold text-sm">{{ $t("action") }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SortLabel from "../SortLabel.vue";

import { mapGetters, mapMutations } from "vuex";
export default {
  components: { SortLabel },
  computed: { ...mapGetters("forms", ["sort"]) },
  methods: {
    ...mapMutations("forms", ["sortForm", "toggleSort"]),
    onSort(by) {
      this.toggleSort();
      this.sortForm(by);
    },
  },
};
</script>
