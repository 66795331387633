<template>
  <div class="relative">
    <button @click.prevent="toggleFilter" class="flex items-center">
      <img src="/icon-filters.svg" alt="filter" />
    </button>
    <Transition name="slide-down-fade">
      <div class="absolute z-10 right-0 top-10 filter_class" v-if="showFilter">
        <div
          class="
            flex flex-col
            items-center
            justify-end
            gap-2
            bg-white
            p-3
            rounded
            shadow-md
          "
        >
          <select-input
            :data="getInputData('name')"
            :placeholder="$t('name')"
            :filterMode="true"
            v-model="name"
            size="small"
            width="full"
          ></select-input>
          <select-input
            :data="getInputData('formNumber')"
            :placeholder="$t('form-number')"
            :filterMode="true"
            v-model="formNumber"
            size="small"
            width="full"
          ></select-input>
          <select-input
            :data="formTypeData"
            :placeholder="$t('form-type')"
            :filterMode="true"
            v-model="type"
            size="small"
            width="full"
          />
          <select-input
            :data="getObjectInputData('group')"
            :placeholder="$t('group')"
            :filterMode="true"
            v-model="group"
            size="small"
            width="full"
          />
          <select-input
            :data="getObjectInputData('subgroup')"
            :placeholder="$t('sub-group')"
            :filterMode="true"
            v-model="subgroup"
            size="small"
            width="full"
          />
          <select-input
            :data="getObjectInputData('sub_subgroup')"
            :placeholder="$t('sub-sub-group')"
            :filterMode="true"
            v-model="sub_subgroup"
            size="small"
            width="full"
          />
          <select-input
            :data="statusData"
            :placeholder="$t('status')"
            :filterMode="true"
            v-model="status"
            size="small"
            width="full"
          />
        </div></div
    ></Transition>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import SelectInput from "@/components/forms/SelectInput.vue";

export default {
  computed: {
    ...mapGetters("forms", ["list"]),
  },
  components: { SelectInput },

  data() {
    return {
      name: "",
      type: "",
      formNumber: "",
      group: "",
      subgroup: "",
      sub_subgroup: "",
      status: "",
      filterData: {
        name: "",
        formNumber: "",
        group: "",
        type: "",
        subgroup: "",
        sub_subgroup: "",
        status: "",
      },
      statusData: [
        { id: 1, value: "Pending" },
        { id: 2, value: "Disabled" },
      ],
      formTypeData: [
        { id: 1, value: "Multiple" },
        { id: 2, value: "Single" },
      ],
      showFilter: false,
    };
  },
  methods: {
    ...mapActions("forms", ["filterForm"]),

    getInputData(name) {
      return this.list.map((li, idx) => ({ id: idx, value: li[name] }));
    },

    getObjectInputData(name) {
      const data = JSON.parse(JSON.stringify(this.list));

      let uniqueData = [];
      data
        .filter((li) => !!li[name])
        .map((li, idx) => ({
          id: idx,
          value: li[name]?.name.toString(),
        }))
        .forEach((item) => {
          const idx = uniqueData.findIndex((uniq) => uniq.value === item.value);
          if (idx < 0) uniqueData.push(item);
        });

      return uniqueData;
    },

    toggleFilter() {
      this.showFilter = !this.showFilter;
    },

    close(e) {
      if (!this.$el.contains(e.target)) {
        this.showFilter = false;
      }
    },
  },
  watch: {
    name(newVal) {
      this.filterData = { ...this.filterData, name: newVal };
      this.filterForm(this.filterData);
    },

    formNumber(newVal) {
      this.filterData = { ...this.filterData, formNumber: newVal };
      this.filterForm(this.filterData);
    },

    group(newVal) {
      this.filterData = { ...this.filterData, group: newVal };
      this.filterForm(this.filterData);
    },

    type(newVal) {
      this.filterData = { ...this.filterData, type: newVal };
      this.filterForm(this.filterData);
    },

    subgroup(newVal) {
      this.filterData = { ...this.filterData, subgroup: newVal };
      this.filterForm(this.filterData);
    },

    sub_subgroup(newVal) {
      this.filterData = { ...this.filterData, sub_subgroup: newVal };
      this.filterForm(this.filterData);
    },

    status(newVal) {
      this.filterData = { ...this.filterData, status: newVal };
      this.filterForm(this.filterData);
    },
  },
  mounted() {
    document.addEventListener("click", this.close);
  },
  beforeUnmount() {
    document.removeEventListener("click", this.close);
  },
};
</script>
