<template>
  <Modal :show="showModal" @onClose="closeModal">
    <template #header>
      {{ selectedForm ? `${$t("edit")} ${selectedForm.name}` : $t("add-form") }}
    </template>
    <template #body
      ><add-form @onClose="closeModal" :form="selectedForm"
    /></template>
  </Modal>

  <Loader v-if="loading && list.length === 0" />
  <template v-else>
    <section class="max-w-screen-2xl mx-auto pt-6 px-2 sm:px-6 lg:px-8">
      <div class="container flex flex-row items-center justify-start">
        <div class="flex items-center">
          <div class="mr-2">
            <img src="/icon-title-forms.svg" class="h-5 w-5" />
          </div>
          <div>
            <h1 class="font-bold text-WADARKBLUE-500 text-base uppercase">
              {{ $t("forms") }}
            </h1>
          </div>
        </div>
      </div>
    </section>

    <section class="max-w-screen-2xl mx-auto px-2 py-6 sm:px-6 lg:px-8">
      <div class="flex items-center justify-between">
        <div class="flex items-center">
          <button
            @click="openModal"
            class="
              bg-WAORANGE-500
              hover:bg-WAORANGE-400
              mr-2
              px-4
              py-2
              rounded-lg
              text-white text-xs
              uppercase
            "
          >
            + {{ $t("add-form") }}
          </button>
        </div>
        <form-filter />
      </div>
    </section>

    <section class="lg:px-8 max-w-screen-2xl mx-auto px-2 sm:px-6">
      <form-table-row />
      <div v-if="error" class="py-3 px-5 mb-3 rounded bg-red-50 text-red-400">
        <span>* {{ error }}</span>
      </div>
      <div v-if="filteredForms.length > 0">
        <FormDetail
          @edit="onEdit"
          v-for="form in filteredForms"
          :key="form._id"
          :form="form"
        />
      </div>
      <div v-else>
        <h1 class="text-sm text-center text-WADARKBLUE-500 my-10">
          {{ $t("no-data") }}
        </h1>
      </div>
    </section>
  </template>
</template>

<script>
import Loader from "@/components/Loader.vue";
import Modal from "@/components/Modal.vue";
import FormFilter from "@/components/form/FormFilter.vue";
import FormTableRow from "@/components/form/FormTableRow.vue";
import FormDetail from "@/components/form/Form.vue";
import AddForm from "@/components/form/AddForm.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    FormFilter,
    FormTableRow,
    FormDetail,
    AddForm,
    Modal,
    Loader,
  },
  computed: {
    ...mapGetters("forms", ["list", "filteredForms", "loading", "error"]),
  },
  async created() {
    await this.getForms();
  },
  data() {
    return {
      showModal: false,
      selectedForm: null,
    };
  },
  methods: {
    ...mapActions("forms", ["getForms"]),
    openModal() {
      this.showModal = true;

      // set to null when adding new form
      this.selectedForm = null;
    },
    closeModal() {
      this.showModal = false;
      this.selectedForm = null;
    },
    onEdit(form) {
      this.showModal = true;
      this.selectedForm = null;
      this.selectedForm = form;
    },
  },
};
</script>
