<template>
  <div
    class="bg-white cursor-pointer hover:bg-WALIGHTBLUE-50 max-w-screen-2xl mb-1 mt-0 mx-auto p-4 rounded-lg shadow-2xl shadow-gray-100 lg:px-4"
  >
    <div class="flex items-center justify-between">
      <div class="flex flex-row">
        <div class="flex pr-1 font-thin text-WADARKBLUE-500 w-44 md:w-80">
          <p class="text-sm truncate">{{ form.name }}</p>
        </div>
        <div class="hidden px-1 w-32 md:flex">
          <p class="font-thin text-WADARKBLUE-500 text-sm">
            {{ form.formNumber }}
          </p>
        </div>

        <!-- <div class="hidden px-1 w-36 lg:flex">
          <p class="font-thin text-WADARKBLUE-500 text-sm truncate">
            {{ form.type }}
          </p>
        </div> -->

        <div class="hidden px-1 w-36 lg:flex">
          <p class="font-thin text-WADARKBLUE-500 text-sm truncate">
            {{ (form.group && form.group?.name) || " " }}
          </p>
        </div>

        <div class="hidden px-1 w-44 lg:flex">
          <p class="font-thin text-WADARKBLUE-500 text-sm truncate">
            {{ (form.subgroup && form.subgroup?.name) || " " }}
          </p>
        </div>

        <div class="hidden w-36 lg:flex">
          <p class="font-thin text-WADARKBLUE-500 text-sm truncate">
            {{ (form.sub_subgroup && form.sub_subgroup?.name) || " " }}
          </p>
        </div>
      </div>
      <div class="flex flex-row">
        <div class="flex flex-row">
          <div class="flex relative justify-end w-20 sm:flex">
            <button
              @click="openStatusModal(form)"
              class="flex font-thin text-sm items-center"
              :class="{
                'text-WAGREEN-500': form.status === 'Active',
                'text-WAORANGE-500': form.status === 'Disabled',
              }"
            >
              {{ form.status }}
              <img
                class="w-2 ml-1"
                src="/chevron-down-dark.svg"
                alt="chevron"
              />
            </button>
            <status-modal
              v-if="showStatus.status && showStatus.id === form._id"
              v-model="status"
              :data="statusData"
              @close="closeStatusModal"
            ></status-modal>
          </div>
        </div>
        <div class="relative flex justify-end w-20 sm:flex">
          <!-- edit dropdown -->
          <option-dropdown-new
            @btnClick="openOptionDropDown(form._id)"
            @onClose="closeOptionDropdown"
            :show="
              showOptionDropdown.status && showOptionDropdown.id === form._id
            "
          >
            <button
              @click="$emit('edit', form)"
              class="px-3 py-2 flex justify-between items-center text-sm w-full text-gray-700 hover:bg-WALIGHTBLUE-50"
            >
              {{ $t("edit") }}
            </button>
            <button
              @click="duplicateForm(form._id)"
              class="px-3 py-2 flex justify-between items-center w-full text-gray-700 text-sm hover:bg-WALIGHTBLUE-50"
            >
              {{ $t("duplicate") }}
            </button>
            <button
              @click="openConfirmModal(form._id)"
              class="px-3 py-2 flex justify-between items-center w-full text-red-700 text-sm hover:bg-red-500 hover:text-white"
            >
              {{ $t("delete") }}
              <!-- <img src="/copy.svg" alt="" /> -->
            </button>
          </option-dropdown-new>

          <div
            class="absolute -bottom-16 w-80"
            v-if="showConfirm.status && showConfirm.id === form._id"
          >
            <confirm-modal
              :show="showConfirm.status && showConfirm.id === form._id"
              @no="closeConfirmModal"
              @yes="confirmDelete(form._id)"
            ></confirm-modal>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
import StatusModal from "../StatusModal.vue";
import ConfirmModal from "@/components/ConfirmModal.vue";
import httpClient from "../../services/httpClient";
import OptionDropdownNew from "../shared/option-dropdown/OptionDropdownNew.vue";

export default {
  components: { StatusModal, ConfirmModal, OptionDropdownNew },
  data() {
    return {
      statusData: [
        { id: 1, value: "Active" },
        { id: 2, value: "Disabled" },
      ],
      status: "",
      showStatus: { status: false, id: "" },
      showOptionDropdown: { status: false, id: "" },
      showConfirm: { status: false, id: "" },
    };
  },
  emits: ["edit"],
  methods: {
    ...mapActions("forms", ["changeStatus"]),
    ...mapMutations("forms", ["addForm", "removeForm"]),
    async duplicateForm(id) {
      try {
        const { data } = await httpClient.post(`/admin/forms/duplicate`, {
          formId: id,
        });

        this.addForm(data);
        this.closeOptionDropdown();
      } catch (err) {
        console.error(err);
      }
    },

    openStatusModal(form) {
      if (this.showOptionDropdown.status) {
        this.showOptionDropdown.status = false;
        this.showOptionDropdown.id = "";
      }

      this.showStatus.id = form._id;
      this.showStatus.status = true;
    },

    openOptionDropDown(id) {
      if (this.showStatus.status) {
        this.showStatus.status = false;
        this.showStatus.id = "";
      }

      this.showOptionDropdown.status = true;
      this.showOptionDropdown.id = id;
    },

    closeOptionDropdown(e) {
      if (!e) {
        this.showOptionDropdown.status = false;
        this.showOptionDropdown.id = "";
      } else if (!this.$el.contains(e.target)) {
        this.showOptionDropdown.status = false;
        this.showOptionDropdown.id = "";
      }
    },

    closeStatusModal(e) {
      if (!e) {
        this.showStatus.status = false;
        this.showStatus.id = "";
      } else if (!this.$el.contains(e.target)) {
        this.showStatus.status = false;
        this.showStatus.id = "";
      }
    },

    async deleteForm(id) {
      try {
        this.deleting = true;

        await httpClient.delete(`/admin/forms/${this.form._id}`);

        this.deleting = false;
        this.removeForm(id);
      } catch (err) {
        this.deleting = false;
        console.error(err);
      }
    },

    confirmDelete(id) {
      this.closeConfirmModal();
      this.deleteForm(id);
    },

    openConfirmModal(id) {
      this.showConfirm.status = true;
      this.showConfirm.id = id;
    },

    closeConfirmModal(e) {
      if (!e) {
        this.showConfirm.status = false;
        this.showConfirm.id = "";
      } else if (!this.$el.contains(e.target)) {
        this.showConfirm.status = false;
        this.showConfirm.id = "";
      }
    },
  },

  props: ["form"],
  mounted() {
    document.addEventListener("click", this.closeStatusModal);
  },

  beforeUnmount() {
    document.removeEventListener("click", this.closeStatusModal);
  },

  watch: {
    async status(status) {
      await this.changeStatus({ status, id: this.form._id });
    },
  },
};
</script>
